/* eslint-disable */
import { Component } from 'react';
import { Images, Utils, _, Trans } from 'Helpers';
import { Button, UncontrolledPopover, PopoverBody, Alert } from 'reactstrap';
import { ReduxHOC } from 'HOC';
import Banner from 'Components/Banner';
class Booster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booster_list: [],
      team_info: {},
      selected_league: Utils.getMultiLeg(),
    }
  }

  getBoosterStatus = (item) => {
    const { team_info, next_week, last_week, joined_week, active_week } = this.state;
    if (!team_info) return null
    const booster_type = {
      1: 'captain_booster_status',
      2: 'bench_booster_status',
      3: 'free_hit_booster_status',
      ...((active_week >= joined_week && last_week != next_week && active_week !== next_week) ? { 0: 'wildcard_status' } : {})
    }
    let obj = team_info[booster_type[item.type]];
    return {
      is_applied: Number(obj.is_applied),
      applied_week: Number(obj.applied_week)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      joined_week,
      last_week,
      active_week,
      next_week,
      updateBooster,
      booster_list,
      team_info,
      SelectedWeek,
    } = nextProps;
    const { t } = this.props

    // let active_week = 1
    // let next_week = 2
    // let last_week = 17
    // let joined_week = 1

    if (this.props.updateBooster != updateBooster) {
      const wildcard_obj = {
        is_removable: 0,
        label: t("Wild Card"),
        text: t("Can be used twice a season, Once in the first half of the season and once in the second half of the season. Can't be cancelled once Played"),
        type: 0
      }
      this.setState({
        booster_list: ((active_week >= joined_week && last_week != next_week && active_week !== next_week) ? [wildcard_obj, ...booster_list] : booster_list),
        team_info: team_info,
        next_week: next_week,
        active_week: active_week,
        last_week: last_week,
        joined_week: joined_week,
        SelectedWeek: SelectedWeek,
      }, () => {

      })
    }
  }
  isAlreadyApplyied = (init_type) => {
    const { t } = this.props;
    const { team_info, booster_list, next_week, last_week, joined_week, active_week } = this.state;
    if (!team_info) return null
    const booster_type = {
      1: 'captain_booster_status',
      2: 'bench_booster_status',
      3: 'free_hit_booster_status',
      ...((active_week >= joined_week && last_week != next_week && active_week !== next_week) ? { 0: 'wildcard_status' } : {})
    }
    let status = false

    for (let i = 0; i < booster_list.length; i++) {
      let info = { ...team_info[booster_type[booster_list[i].type]], type: booster_list[i].type }
      // console.log(init_type, 'Want to apply');
      if (info.applied_week == next_week) {
        // console.log(info.type, 'Applied booster type');
        // console.log(info.type == 1 && init_type == 0);
        if (
          (info.type == 1 && init_type == 0)
          || (info.type == 0 && init_type == 1)
          || (info.type == 0 && init_type == 2)
        ) {
          status = false
          break;
        } else {
          if (init_type == 0) {
            Utils.notify(t('You cannot apply wild card with free hit booster in single week'), 'error');
          }
          if (init_type == 3) {
            Utils.notify(t('You cannot apply free hit booster with wild card in single week'), 'error');
          }
          status = true
          break;
        }
      }
      // if(info.applied_week == next_week) {
      //   status = true
      //   break;
      // }
    }

    return status
    // return team_info[booster_type[item.type]]

  }

  render() {
    const {
      ApplyBooster,
      RemoveBooster,
      BoosterPosting,
      joined_week,
      last_week,
      active_week,
      LineupList,
      read_only,
      IS_MYTEAM,
      lineup_master_id
    } = this.props;
    let { SelectedWeek, booster_list, selected_league, team_info, next_week } = this.state;
    return (
      <div className={IS_MYTEAM && !_.isUndefined(lineup_master_id) ? 'booster-wrap lineup-alter' : "booster-wrap"}>
        {
          team_info &&
          _.map(booster_list, (item, idx) => {
            if (!this.getBoosterStatus(item)) return null

            return (
              <div
                id={`BoosterType${item.type}`}
                {
                ...{
                  className: `booster-item
                   ${(this.getBoosterStatus(item).is_applied == 0 || this.getBoosterStatus(item).applied_week > SelectedWeek) ?
                      '' :
                      `${this.getBoosterStatus(item).applied_week == SelectedWeek && 'applied'} ${this.getBoosterStatus(item).applied_week < SelectedWeek && 'played'}`
                    }
                   `,
                  key: idx,
                  style: { display: read_only ? (this.getBoosterStatus(item).applied_week == SelectedWeek ? 'inline-flex' : 'none') : 'inline-flex' }
                }
                }>
                <img src={Images[`BOOSTER_${item.type}`]} alt="" />
                <div className='cell'>
                  <div>

                    {
                      (this.getBoosterStatus(item).is_applied == 0 || this.getBoosterStatus(item).applied_week > SelectedWeek) ?
                        <Trans>Available Booster</Trans> :
                        <>
                          {
                            this.getBoosterStatus(item).applied_week == SelectedWeek &&
                            <>
                              <Trans>Applied</Trans>: {selected_league.week_abbr[this.getBoosterStatus(item).applied_week]}
                              {
                                BoosterPosting && item.type != 0 ?
                                  <i className="icon-close disabled" />
                                  :
                                  (this.getBoosterStatus(item).applied_week == SelectedWeek && SelectedWeek == next_week && item.is_removable == 1) &&
                                  <i className="icon-close click" onClick={(e) => RemoveBooster(e, item.type)} />
                              }
                            </>
                          }
                          {
                            this.getBoosterStatus(item).applied_week < SelectedWeek &&
                            <><Trans>Played</Trans>: {selected_league.week_abbr[this.getBoosterStatus(item).applied_week]}</>
                          }
                        </>
                    }
                  </div>
                  <b>{item.label}</b>
                </div>
              </div>
            )
          })
        }

        {
          true &&
          <>
            {
              !_.isEmpty(booster_list) &&
              _.map(booster_list, (item, i) => {
                return (
                  <UncontrolledPopover key={i}
                    placement="bottom"
                    target={`BoosterType${item.type}`}
                    trigger="legacy"
                    hideArrow
                    disabled={
                      (SelectedWeek != next_week)
                      || (next_week == last_week && last_week == active_week)
                      || (this.getBoosterStatus(item).applied_week != 0 && this.getBoosterStatus(item).applied_week < next_week)
                      || BoosterPosting
                      || read_only
                    }
                  >
                    <PopoverBody className='booster-popover'>
                      <div>
                        <Banner type="14" style={{ height: '106px', width: '34px' }} />
                      </div>
                      <div className="booster-img">
                        <span style={{ fontSize: '10px' }}><Trans>Official Partner</Trans></span>
                        <br />
                        <img src={Images[`BOOSTER_${item.type}`]} alt="" />
                      </div>
                      <div className="booster-title">
                        {item.label}
                      </div>
                      <p>{item.text}</p>
                      {/* {
                        console.log(item)
                      } */}

                      {
                        (this.getBoosterStatus(item).is_applied == 0 || this.getBoosterStatus(item).applied_week > SelectedWeek) ?
                          <>

                            {
                              Utils.isCapAvailable(LineupList) ?
                                <Button block onClick={() => ApplyBooster(item.type, this.isAlreadyApplyied(item.type))}
                                  disabled={
                                    BoosterPosting
                                    || (joined_week == next_week && item.type == 3)
                                  }><Trans>Apply</Trans></Button>
                                :
                                <Alert color="danger" className='over-budget-msz'><Trans>Choose player to make captain first, Then you will be able to apply 3X booster</Trans></Alert>
                            }
                          </>

                          :
                          <>
                            {
                              this.getBoosterStatus(item).applied_week == SelectedWeek &&
                              <>
                                {
                                  item.is_removable == 1 ?
                                    <Button block className='applied' disabled={BoosterPosting} onClick={(e) => RemoveBooster(e, item.type)}><Trans>Remove</Trans></Button>
                                    :
                                    <Button block disabled><Trans>Applied</Trans></Button>
                                }
                              </>
                            }
                            {
                              this.getBoosterStatus(item).applied_week < SelectedWeek &&
                              <Button block className='played'><Trans>Played</Trans></Button>
                            }
                          </>
                      }
                    </PopoverBody>
                  </UncontrolledPopover>
                )
              })
            }
          </>
        }
      </div>
    );
  }
}
// booster_list
Booster.defaultProps = {
  booster_list: [],
  read_only: false
}
export default ReduxHOC(Booster);